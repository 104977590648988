import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import styled from "styled-components";
import NotFoundFox from "../icons/NotFoundFox";

const Wrapper = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5rem auto 0rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin: 10rem auto 5rem;
  }
`;

const Side = styled.div`
  padding: 0 1rem;
  margin-bottom: 5rem;
  flex-basis: 0 0 300px;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding: 2rem;
  }

  svg {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamilyBold};
  text-transform: uppercase;
`;

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <meta name="prerender-status-code" content="404" />
    </Helmet>
    <Wrapper>
      <Side>
        <Title>404 - Page not found</Title>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Side>
      <Side>
        <NotFoundFox />
      </Side>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
