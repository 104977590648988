import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={487} height={223} {...props}>
      <defs>
        <linearGradient
          x1="-.003%"
          y1="50%"
          x2="100.002%"
          y2="50%"
          id="prefix__a"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50.001%"
          x2="100%"
          y2="50.001%"
          id="prefix__b"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="50%"
          x2="99.999%"
          y2="50%"
          id="prefix__c"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="49.998%"
          x2="100%"
          y2="49.998%"
          id="prefix__d"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient x1=".001%" y1="50%" x2="100%" y2="50%" id="prefix__e">
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50.001%"
          x2="99.999%"
          y2="50.001%"
          id="prefix__f"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50.001%"
          x2="100.001%"
          y2="50.001%"
          id="prefix__g"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="50.001%"
          x2="99.999%"
          y2="50.001%"
          id="prefix__h"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50.002%"
          x2="99.998%"
          y2="50.002%"
          id="prefix__i"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="50.005%"
          x2="100%"
          y2="50.005%"
          id="prefix__j"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="49.999%"
          x2="99.999%"
          y2="49.999%"
          id="prefix__k"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50.001%"
          x2="99.999%"
          y2="50.001%"
          id="prefix__l"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="49.998%"
          x2="99.999%"
          y2="49.998%"
          id="prefix__m"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="49.999%"
          x2="99.999%"
          y2="49.999%"
          id="prefix__n"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100.001%" y2="50%" id="prefix__o">
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50%"
          x2="99.999%"
          y2="50%"
          id="prefix__p"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="49.999%"
          x2="99.999%"
          y2="49.999%"
          id="prefix__q"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50.003%"
          x2="100.001%"
          y2="50.003%"
          id="prefix__r"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50.004%"
          x2="99.997%"
          y2="50.004%"
          id="prefix__s"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50.001%"
          x2="99.999%"
          y2="50.001%"
          id="prefix__t"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="50.001%"
          x2="100.001%"
          y2="50.001%"
          id="prefix__u"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="50.001%"
          x2="100.001%"
          y2="50.001%"
          id="prefix__v"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="99.999%" y2="50%" id="prefix__w">
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="49.999%"
          x2="99.999%"
          y2="49.999%"
          id="prefix__x"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".003%"
          y1="50.001%"
          x2="100%"
          y2="50.001%"
          id="prefix__y"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.003%"
          y1="50.002%"
          x2="100.001%"
          y2="50.002%"
          id="prefix__z"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="49.999%"
          x2="99.999%"
          y2="49.999%"
          id="prefix__A"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="50.002%"
          x2="100%"
          y2="50.002%"
          id="prefix__B"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="50.001%"
          x2="100%"
          y2="50.001%"
          id="prefix__C"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="49.998%"
          x2="99.997%"
          y2="49.998%"
          id="prefix__D"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="49.999%"
          x2="100%"
          y2="49.999%"
          id="prefix__E"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".002%"
          y1="50%"
          x2="99.997%"
          y2="50%"
          id="prefix__F"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="50%"
          x2="99.999%"
          y2="50%"
          id="prefix__G"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#E45C90" offset=".496%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".001%"
          y1="50%"
          x2="99.999%"
          y2="50%"
          id="prefix__H"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50.001%"
          x2="100.002%"
          y2="50.001%"
          id="prefix__I"
        >
          <stop stopColor="#E45C90" offset="0%" />
          <stop stopColor="#F09C60" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.001%"
          y1="50.003%"
          x2="99.999%"
          y2="50.003%"
          id="prefix__J"
        >
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".003%"
          y1="50.001%"
          x2="100%"
          y2="50.001%"
          id="prefix__K"
        >
          <stop stopColor="#EF7F1E" offset="0%" />
          <stop stopColor="#EF7F1E" offset=".02%" />
          <stop stopColor="#E62063" offset="100%" />
        </linearGradient>
        <linearGradient x1="-.001%" y1="50%" x2="100%" y2="50%" id="prefix__L">
          <stop stopColor="#F09C60" offset="0%" />
          <stop stopColor="#EF7F1E" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none">
        <path
          d="M223.903 29.105C225.16 28.512 256.187 0 256.187 0l-5.031 91.01-27.253 41.905V29.105z"
          fill="url(#prefix__a)"
          transform="matrix(1 0 0 -1 .699 133.145)"
        />
        <path
          d="M251.156 22.247L312.791 0l97.005 22.247c-25.42 8.75-52.025 13.394-52.025 13.394L223.903 64.152l27.253-41.905z"
          fill="url(#prefix__b)"
          transform="matrix(1 0 0 -1 .699 64.383)"
        />
        <path
          d="M423.484 62.855l61.608-20.95c-6.921 20.683-22.527 52.095-42.96 66.247-8.552 5.924-20.313 13.141-32.336 17.281l13.688-62.578z"
          fill="url(#prefix__c)"
          transform="matrix(1 0 0 -1 .699 167.57)"
        />
        <path
          fill="url(#prefix__d)"
          transform="matrix(1 0 0 -1 .699 267.268)"
          d="M365.623 132.025l101.468-29.704 18.001 39.283-61.608 23.112z"
        />
        <path
          d="M409.796 135.012l-97.005-22.245c1.678-1.678 52.832-70.862 52.832-70.862l57.861 32.692-13.684 60.415h-.004z"
          fill="url(#prefix__e)"
          transform="matrix(1 0 0 -1 .699 177.148)"
        />
        <path
          fill="url(#prefix__f)"
          transform="matrix(1 0 0 -1 .699 251.099)"
          d="M122.433 110.825l25.996-31.16 24.739 15.227 2.165 2.227-17.469 74.084z"
        />
        <path
          fill="url(#prefix__g)"
          transform="matrix(1 0 0 -1 .699 239.227)"
          d="M115.724 120.757l-3.353-21.804h10.062l-2.934 28.092-14.256 12.998z"
        />
        <path
          d="M56.605 163.524l-2.936-23.481 17.263 1.41 23.828 1.944 17.61 36.06-55.765-15.933zm9.086-13.992c2.698 1.801 7.52 4.227 13.556 3.823l-13.035-4.63-2.899-1.03s.856.821 2.378 1.837"
          fill="url(#prefix__h)"
          transform="matrix(1 0 0 -1 .699 319.73)"
        />
        <path
          fill="url(#prefix__i)"
          transform="matrix(1 0 0 -1 .699 387.555)"
          d="M70.728 209.277l.01-.017-17.069-1.393-11.918-31.764 35.812 22.962 16.993 12.156z"
        />
        <path
          fill="url(#prefix__j)"
          transform="matrix(1 0 0 -1 .699 369.075)"
          d="M2.201 192.741L0 177.228l31.276-6.025 1.533 4.97-14.196 17.799-2.926 3.67z"
        />
        <path
          fill="url(#prefix__k)"
          transform="matrix(1 0 0 -1 .699 242.58)"
          d="M256.187 109.435l61.122-23.062-12.484 69.603z"
        />
        <path
          d="M304.825 112.79l60.798-48.638s-51.154 69.183-52.832 70.86l-7.966-22.221z"
          fill="url(#prefix__l)"
          transform="matrix(1 0 0 -1 .699 199.395)"
        />
        <path
          fill="url(#prefix__m)"
          transform="matrix(1 0 0 -1 .699 293.628)"
          d="M233.544 134.905l73.377-1.99 10.388 4.506-61.122 23.062z"
        />
        <path
          fill="url(#prefix__n)"
          transform="matrix(1 0 0 -1 .699 242.58)"
          d="M317.31 86.373l48.313 20.964-60.798 48.639z"
        />
        <path
          fill="url(#prefix__o)"
          transform="matrix(1 0 0 -1 .699 175.05)"
          d="M256.187 41.905l48.638 46.542 7.966 22.221-61.635 22.247z"
        />
        <path
          fill="url(#prefix__p)"
          transform="matrix(1 0 0 -1 .699 153.98)"
          d="M157.864 74.084L175.334 0l48.569 49.94v103.81z"
        />
        <path
          d="M112.37 204.104l-17.61-36.06-8.281-25.89c.002 0 26.73-1.782 31.761-2.621 5.032-.839 31.867 16.772 31.867 16.772a3744.681 3744.681 0 0125.553 4.733l-25.553-2.637-1.678 14.544-25.996 31.159h-10.062z"
          fill="url(#prefix__q)"
          transform="matrix(1 0 0 -1 .699 343.839)"
        />
        <path
          fill="url(#prefix__r)"
          transform="matrix(1 0 0 -1 .699 357.179)"
          d="M150.107 171.203l25.553 2.637-7.105 11.303-20.126.602z"
        />
        <path
          fill="url(#prefix__s)"
          transform="matrix(1 0 0 -1 .699 333.988)"
          d="M34.553 164.805L36.48 154.3h17.19l2.936 23.481-24.738 1.676z"
        />
        <path
          d="M148.43 167.881l20.125-.602 7.105-11.303c30.587 5.777 48.243 9.521 48.243 9.521l-15.935 13.104-34.8 4.507-24.739-15.227z"
          fill="url(#prefix__t)"
          transform="matrix(1 0 0 -1 .699 339.315)"
        />
        <path
          fill="url(#prefix__u)"
          transform="matrix(1 0 0 -1 .699 396.79)"
          d="M77.564 208.3l-35.813-22.963-2.031-9.234 46.759 18.462 8.281 25.891z"
        />
        <path
          fill="url(#prefix__v)"
          transform="matrix(1 0 0 -1 .699 393.281)"
          d="M36.479 213.593l-9.768-34.136 15.04 2.372 11.918 31.764z"
        />
        <path
          fill="url(#prefix__w)"
          transform="matrix(1 0 0 -1 .699 320.57)"
          d="M317.31 164.363l-10.389-4.507 46.962-24.844 11.74 50.315z"
        />
        <path
          fill="url(#prefix__x)"
          transform="matrix(1 0 0 -1 .699 346.04)"
          d="M253.252 166.352h53.67l46.96-5.87-46.96 24.845z"
        />
        <path
          d="M10.063 152.623l26.416-20.127-1.926 10.505-2.686 14.652-11.088 21.804-10.716-26.834zm15.41-1.152s5.58-1.216 9.08-8.47l-9.08 8.47z"
          fill="url(#prefix__y)"
          transform="matrix(1 0 0 -1 .699 312.184)"
        />
        <path
          d="M223.903 164.363l10.9-5.871h18.449l-19.708 20.965s-9.223-12.577-9.641-15.094"
          fill="url(#prefix__z)"
          transform="matrix(1 0 0 -1 .699 338.18)"
        />
        <path
          d="M173.168 121.068l34.8-4.505 15.935-13.104c.418 2.516 9.641 15.093 9.641 15.093l22.836 25.795-32.477 29.38-50.735-52.659z"
          fill="url(#prefix__A)"
          transform="matrix(1 0 0 -1 .699 277.416)"
        />
        <path
          fill="url(#prefix__B)"
          transform="matrix(1 0 0 -1 .699 338.18)"
          d="M253.252 158.492l53.67 18.975-73.378 1.99z"
        />
        <path
          fill="url(#prefix__C)"
          transform="matrix(1 0 0 -1 .699 182.204)"
          d="M46.121 88.052l2.099-23.06 30.188-6.29 9.644 19.462 6.639 1.876-20.058 43.232z"
        />
        <path
          d="M48.22 90.567l-2.099 23.06-36.058 3.354s28.93-15.094 38.157-26.414"
          fill="url(#prefix__D)"
          transform="matrix(1 0 0 -1 .699 207.779)"
        />
        <path
          fill="url(#prefix__E)"
          transform="matrix(1 0 0 -1 .699 273.188)"
          d="M20.779 140.461l11.088-21.804 24.738-1.676 55.766 15.934-33.963 16.77-30.188 6.291z"
        />
        <path
          d="M20.779 90.567L48.22 106.08c-9.227 11.32-38.157 26.415-38.157 26.415l10.716-41.93z"
          fill="url(#prefix__F)"
          transform="matrix(1 0 0 -1 .699 223.293)"
        />
        <path
          fill="#25204B"
          d="M42.45 211.452l-1.991-2.135-1.99-2.133-6.68-1.114-2.674 2.494-1.704 5.26 2.354 5.465 5.287 2.732 5.368-1.335 2.672-2.492z"
        />
        <path
          fill="url(#prefix__G)"
          transform="matrix(1 0 0 -1 .699 198.975)"
          d="M94.69 96.81l10.553 2.98 14.256-12.997 2.934-28.092 35.431 60.378-83.23 20.964z"
        />
        <path
          fill="url(#prefix__H)"
          transform="matrix(1 0 0 -1 .699 79.896)"
          d="M162.685 63.732L74.633 20.964 157.864 0l66.039 79.665z"
        />
        <path
          fill="url(#prefix__I)"
          transform="matrix(1 0 0 -1 .699 239.227)"
          d="M96.84 132.43l-18.432-16.706 33.963-16.77 3.353 21.803-10.481 19.286z"
        />
        <path
          fill="url(#prefix__J)"
          transform="matrix(1 0 0 -1 .699 222.456)"
          d="M94.69 120.292l-6.638-1.876-9.644-19.463L96.84 115.66l8.403 7.613z"
        />
        <path
          d="M66.388 170.2c-1.521 1.015-2.376 1.835-2.376 1.835l2.899-1.03 13.035-4.63c-6.035-.402-10.86 2.022-13.558 3.824m-40.217-9.485l9.081 8.469c-3.499-7.255-9.081-8.47-9.081-8.47"
          fill="#25204B"
        />
        <path
          fill="url(#prefix__K)"
          transform="matrix(1 0 0 -1 .699 352.232)"
          d="M15.687 180.799l17.122-21.468 3.67 13.213-26.416 20.127z"
        />
        <path
          fill="url(#prefix__L)"
          transform="matrix(1 0 0 -1 .699 320.57)"
          d="M353.883 135.012l113.208 20.61-101.468 29.705z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
